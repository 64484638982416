<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">{{ $t('MENU.PURCHASE.NOTTAKEN') }}</h3>
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="mainAssets"
              no-data-text="Malumot kiritilmagan"
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.action`]="{ item }">
                <Action :index="item"></Action>
              </template>
            </v-data-table>
            <div class="text-left mt-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const data = [1, 2, 3, 4, 5]
data.slice(1, 2)
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import Action from './Action'
export default {
  data() {
    return {
      currentPage: 1
    }
  },
  created() {
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(this.$route.query.page))
      this.currentPage = parseInt(this.$route.query.page, 10)
    this.$store.dispatch('mainAssetsTaken', this.currentPage)
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('TABLE_HEADER.CONTRAGENT'), value: 'contragent_name' },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'oper_date' },
        { text: this.$t('TABLE_HEADER.REG_NUMBER'), value: 'reg_number' },
        { text: this.$t('TABLE_HEADER.AMOUNT'), value: 'get_doc_total.total' },
        {
          text: this.$t('TABLE_HEADER.NDSAMOUNT'),
          value: 'get_doc_total.nds_summa'
        },
        {
          text: this.$t('TABLE_HEADER.NDS_AMOUNT'),
          value: 'get_doc_total.total_withnds_summa'
        },
        { text: '', value: 'action', sortable: false }
      ]
    },
    getCount() {
      return this.$store.state.requests.mainAssetsTaken
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    mainAssets() {
      return this.$store.state.requests.mainAssetsTaken.results
    }
  },
  methods: {
    getPostData(page) {
      console.log(this.$router.currentRoute.path)
      this.$router.push({
        path: this.$router.currentRoute.path,
        query: { page: page }
      })
      this.$store.dispatch('mainAssetsTaken', page)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.PURCHASE.TITLE') },
      { title: this.$t('MENU.PURCHASE.NOTTAKEN') }
    ])
  },
  components: {
    Action
  }
}
</script>

<style scoped>
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
</style>
